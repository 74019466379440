import { useDispatch } from 'react-redux';
import { login, logout } from '../store';

const useAuth = () => {
	//
	const dispatch = useDispatch();

	return {
		login: credential => {
			//
			dispatch(login(credential));
			sessionStorage.setItem('token', credential);
		},
		logout: () => {
			//
			dispatch(logout());
			sessionStorage.removeItem('token');
		}
	};
};

export default useAuth;
