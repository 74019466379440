import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		token: sessionStorage.getItem('token') || null
	},
	reducers: {
		login: (state, action) => {
			state.token = action.payload;
		},
		logout: (state, action) => {
			state.token = null;
		}
	}
});

export { authSlice };
