import useAuth from '../../hooks/useAuth';
import useModalContext from '../../hooks/useModalContext';
import Image from '../Image';
import Modal from './Modal';
import { GoogleLogin } from '@react-oauth/google';
import { useSelector } from 'react-redux';

const Layout = ({ children }) => {
	//
	const { modal } = useModalContext();
	const auth = useSelector(state => state.auth);

	const { login, logout } = useAuth();

	const handleLoginSuccess = response => {
		login(response.credential);
	};

	const handleLoginFailure = response => {
		alert('Login Failed');
		logout(null);
	};

	const handleLogout = response => {
		logout(null);
	};

	return (
		<>
			{modal && <Modal />}
			<header>
				<div className="container">
					<nav className="level is-mobile">
						<div className="level-left">
							<div className="level-item">
								<Image src="logo.png" />
							</div>
						</div>
						<div className="level-right">
							<div className="level-item">
								{auth.token ? (
									<button className="button is-danger is-light" onClick={handleLogout}>
										Logout
									</button>
								) : (
									<GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} useOneTap />
								)}
							</div>
						</div>
					</nav>
				</div>
			</header>

			<div className="container main">{auth.token && children}</div>
		</>
	);
};

export default Layout;
