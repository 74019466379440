import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from '.';

const extendFetchBaseQuery = (args, api, extraOptions) => {
	return fetchBaseQuery(
		{
			baseUrl: process.env.REACT_APP_BACKEND_URL,
			...args,
			prepareHeaders: (headers, { getState }) => {
				const token = getState().auth.token;

				if (token) {
					headers.set('authorization', `Bearer ${token}`);
				}

				return headers;
			}
		},
		api,
		extraOptions
	);
};

export const authedExtendFetchBaseQuery = (args, api, extraOptions) => {
	//
	const baseQuery = extendFetchBaseQuery(args, api, extraOptions);

	return async (args, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions);

		if (result.error && result.error.status === 401) {
			api.dispatch(logout());
		}

		return result;
	};
};
