import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authSlice } from './slices/authSlice';
import { exportApi } from './apis/exportApi';
import { appApi } from './apis/appApi';

const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		[exportApi.reducerPath]: exportApi.reducer,
		[appApi.reducerPath]: appApi.reducer
	},
	middleware: getDefaultMiddleware => {
		return getDefaultMiddleware().concat([exportApi.middleware, appApi.middleware]);
	}
});

setupListeners(store.dispatch);

export { store, authSlice };

export const { login, logout } = authSlice.actions;

export { useSearchUnitQuery, useExportReportQuery } from './apis/exportApi';
export { useConfigQuery } from './apis/appApi';
