import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Layout from './components/layout/Layout';
import { Provider } from 'react-redux';
import { store } from './store';
import { AppProvider } from './context/app';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
		<Provider store={store}>
			<AppProvider>
				<Layout>
					<App />
				</Layout>
			</AppProvider>
		</Provider>
	</GoogleOAuthProvider>
);
